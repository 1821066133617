import { isObject, getParameterByName, getKeyByValue, clearLocationSearch, addQueryStringValues, getDateUTCParams, toDashCase } from "@helpers/utils.js"
import { createApp, reactive, watch } from 'vue';
import Enum from "@shared/enum.js";
import model from "./site/js/model.js";
import controller from "./site/js/controller/controller.js";
import ZendeskHelper from "@dom/zendesk-helper.js";
import LangHandler from "@dom/lang-handler.js";
import globalFunctions from "./site/js/global-functions.js";

import Root from "./site/vue/root-view.vue";
import PreloadIcon from "./site/vue/shared/preload-icon.vue";

import customJqueryPlugins from '@plugins/custom-jquery-plugins.js';
import '@plugins/iscroll-swipe-fix.js';

import EgoeEvents from "@directives/egoe-events.js";
import ScrollLockDirective from "@directives/scroll-lock-directive.js";
import IscrollDirective from "@directives/iscroll-directive.js";
import LangDirective from "@directives/lang.js";

import gameListDirective from '@directives/game-list-directive.js';
import pagesDirective from "@directives/pages-directive.js";
import viewportLoadDirective from '@directives/async-load-directive.js';

import '@css/rows-columns.scss';
import '@css/buttons.scss';
import '@css/eagle-tabs.scss';
import '@css/network-err-prompt.scss';
import '@css/bootstrap-text-align-classes.scss';
import '@css/views.scss';
import '@css/fit-text.scss';
import '@css/sticky-notif.scss';

import { NoLabel } from '@lang/cashier.js';

import { imgParallax, preloadImg } from "@directives/general.js";
import asCurrency from "@directives/as-currency.js";
import viewMap from "./view-map.js";
import { CopiedLabel } from "@lang/misc.js";

(() => {
	if(!initAppData.cconfigs) {
		importView();
		return;
	}
	
	(new Promise(resolve => {
		loadConfig();
		function loadConfig(index){
			index = index || 0;

			let target = initAppData.cconfigs[initAppData.config.siteKey];

			fetch(target[index]).then(req => {
				req.text().then(text => {
					(new Function(text))();
					index++;
					if(target.length === index) resolve();
					else loadConfig(index);
				});
			});
		}
	})).then(importView);
})();

async function importView(){
	await controller.postFlutterMessage('Flutter_WebView_PrepareFlutterCookies');

	const views = import.meta.glob(['./site/vue/views/*/*/*-view.vue', './site/vue/views/*/*-view.vue', './site/vue/views/*-view.vue']);

	let targetView = null;

	let item = viewMap[initAppData.viewName];
	item.path.every(path => {
		let view = views[`./site/vue/views/${path.replace('[site-key]', initAppData.config.siteKey)}-view.vue`];
		if(!view) return true;

		targetView = view;
		model.viewExpectingGames = item.expectingGames;
		model.view = item.viewId;
		return false;
	});

	initApp((await targetView()).default);
}

function initApp(App){
	let deferredClientConfigs = initAppData.deferredClientConfigs;
	let extraEvents = initAppData.extraEvents;

	model.isProd = initAppData.isProd;

	initAppData.config.model = model;

	window.cconfig.forEach(cbx => cbx(initAppData.config, Enum, {
		getParameterByName,
		addQueryStringValues,
		getDateUTCParams,
	}));
	
	delete window.initAppData;

	window.EGOE = window.EGOE || {};
	window.EGOE = Object.assign(window.EGOE, globalFunctions);

	model.init();
	
	for(let eventName in extraEvents) model.on(eventName, extraEvents[eventName]);
	
	model.cconfPaths = deferredClientConfigs;
	
	model.columnResAmount = [
		{
			maxWidth: 479,
			amount: 3,
		},
		{
			minWidth: 480,
			maxWidth: 1023,
			amount: 4,
		},
		{
			minWidth: 1024,
			maxWidth: 1280,
			amount: 5,
		},
		{
			minWidth: 1281,
			amount: {
				FOUR_SG: 6,
				FUND: 5,
			}
		},
	];
	
	model.columnResAmount.forEach(item => {
		Object.keys(item).forEach(key => {
			item[key] = model.redeemLangObj(item[key]);
		});
	});
	
	model.vue = {
		app: null,
		root: null,
	}

	Root.components.App = App;
	model.vue.app = createApp(Root);
	model.vue.app.component('PreloadIcon', PreloadIcon);

	model.pushLang({
		NoLabel, 
		CopiedLabel,
	});

	[{asCurrency}, {imgParallax}, {preloadImg}].forEach(dir => {
		let key = Object.keys(dir)[0];
		model.vue.app.directive(toDashCase(key), dir[key]);
	});

	viewportLoadDirective();
	pagesDirective();
	gameListDirective();
	EgoeEvents();
	ScrollLockDirective();
	IscrollDirective();
	LangDirective();
	customJqueryPlugins();
	
	model.observables = reactive({});
	
	model.vue.app.mixin({
		data(){
			return {
				eventCallbacks: {},
			}
		},
		methods: {
			getProp(propName){
				let parent = null;
	
				do{
					parent = parent ? parent.$parent : this;
					let ctx = parent.$.ctx;
					let data = parent.$.data;
					if(isObject(ctx) && ctx.hasOwnProperty(propName)) return parent[propName];
					if(isObject(data) && data.hasOwnProperty(propName)) return data[propName];
				}while(parent)
	
				throw 'Unknown property.';
			},
			addEvent(){
				let args = Array.prototype.slice.apply(arguments);
				let eventCallback = args[args.length - 1];
				if(typeof eventCallback !== 'function') return;

				args.forEach(eventName => {
					if(typeof eventName !== 'string') return;
					this.eventCallbacks[eventName] ||= [];
					this.eventCallbacks[eventName].push(eventCallback);
				});

				model.on.apply(model, args);
			},
		},
		unmounted(){
			Object.keys(this.eventCallbacks).forEach(eventName => {
				this.eventCallbacks[eventName].forEach(eventCallback => model.off(eventName, eventCallback));
				delete this.eventCallbacks[eventName];
			});
		},
		mounted(){
			if(this.$el) this.$el.getComponent = () => this;
			if(typeof this.loadName === 'string') {
				!model.loadedComponents.includes(this.loadName) && model.loadedComponents.push(this.loadName);
				model.dispatchEvent(`${this.loadName}ComponentLoaded`);
			}
		},
		computed: (() => {
			let obj = {model, controller, jQuery, Enum};
			let res = {};
	
			for(let key in obj){
				res[key] = {
					get(){
						return obj[key];
					}
				};
			}
	
			return res;
		})(),
	});
	
	controller.init();
	
	controller.langHandler = new LangHandler(model, controller);
	new ZendeskHelper();
	
	var oldWW = $(window).width();
	$(window).on('resize', e => {
		var newWW = $(window).width();
	
		model.dispatchEvent('ForceResize');
		if (model.isMobile && oldWW == newWW) {
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation();
			return false;
		}
	
		oldWW = newWW;
	});
	
	let $html = $('html');
	model.isMobile && $html.addClass('mobile');

	(model.isSafari || getParameterByName('forceSafari') == 'true') && $html.addClass('is-safari');
	(model.isIOS) && $html.addClass('ios');

	$html.attr('data-view', (getKeyByValue(Enum.Views, model.view) + '').toLowerCase());

	watch(() => model.observables.gameDisplayedObs, bool => {
		$html[bool ? 'addClass' : 'removeClass']('game-displayed');
	});
	
	window.addEventListener('resize', () => {
		model.dispatchEvent('WindowResize');
	});
	
	var lastTouchEnd = 0;
	document.addEventListener('touchend', event => {
		var now = (new Date()).getTime();
		if (now - lastTouchEnd <= 300) {
			event.preventDefault();
		}
		lastTouchEnd = now;
	}, false);
	
	/**
	 * Important to reference the showNotification on the window object so that
	 * the games could access lobby's notificaiton feature.
	 */
	window.showNotification = params => {
		controller.showNotification(params);
	};
	
	clearLocationSearch('ns');

	model.vue.app.mount('#root');
	
	model.dispatchEvent('BindingsApplied');
	
	$(document).on('shownbsmodal', () => {
		$(document).off('focusin.modal');
	});

	if(localStorage.getItem('show-app-version-test') === 'true' || !model.isProd){
		$(`<div id="show-app-version-test">${model.lobbyVersion}</div>`).appendTo('body');
		$(`
			<style>
				#show-app-version-test{
					position: absolute;
					z-index: 99999;
					pointer-events: none;
					top: 0;
					left: 0;
					color: #ccc;
					font-weight: 600;
					font-size: 10px;
				}
			</style>
		`).appendTo('body');
	}

	window.modelTest = model;
	window.controllerTest = controller;
}