export const SwipeLeftRightLabel = { 
	"en": "swipe left or right ",
	"el": "σύρε αριστερά ή δεξιά",
	"de": "swipe nach links oder rechts",
	"es": "Desliza izquierda o derecha"
}

export const PlayForMoneyText = {
	"en": "You need to be logged in to play for money!",
	"el": "Χρειάζεται να συνδεθείς για να παίξεις με χρήματα!",
	"de": "Du musst angemeldet sein, um um Geld zu spielen!",
	"es": "¡Necesitas iniciar sesión para jugar con dinero!"
}