import model from "@js/model.js";
import { removeEmptyFromArr } from "@helpers/utils.js";

let bcFavTimeout;

export default  {
	initPersonalSettings(){
		model.soundEnabled = true;

		if(typeof model.recentlyPlayedActive !== 'boolean') model.recentlyPlayedActive = false;

		model.on('SetLang', () => {
			if(!model.activeGames) return;

			var activeGameEgoe = model.activeGames['egoe'];
			if(activeGameEgoe?.loaded){
				var contentWindow = activeGameEgoe.contentWindow;
				contentWindow && contentWindow.postMessage({
					type: 'LanguageChanged',
					lang: model.lang == 'el' ? 'gr' : model.lang,
				}, '*');
			}
		});
	},
	postToggleSoundMessage(){
		var activeGameEgoe = model.activeGames['egoe'];
		var contentWindow = activeGameEgoe.loaded ? activeGameEgoe.contentWindow : null;
		contentWindow && contentWindow.postMessage({
			type: 'ToggleSound',
			mute: !model.soundEnabled,
		}, '*');
	},
	addToFav(gi, callback, global) {
		if(typeof global === 'boolean') global = true;
		this.updatePersonalSettings({
			favoriteGames: parseFavGames.call(this, gi, false),
		}, callback, global);
	},
	removeFromFav(gi, callback, global) {
		if(typeof global === 'boolean') global = true;
		this.updatePersonalSettings({
			favoriteGames: parseFavGames.call(this, gi, true),
		}, callback, global);
	},
	saveChosenLang(langKey){
		return new Promise(resolve => {
			if(!model.logged || model.personalSettings.lang == model.langKey) {
				resolve();
				return;
			}
	
			this.updatePersonalSettings({
				lang: langKey,
			}, () => resolve());
		});
	},
	saveSoundEnabled(){
		if(!model.logged || model.soundEnabled == model.personalSettings.soundEnabled) return;

		this.updatePersonalSettings({
			soundEnabled: model.soundEnabled
		});
	},
	savePaysafeToken(token, callback){
		if(!model.logged) return;

		this.updatePersonalSettings({
			paysafeToken: token
		}, callback);
	},
	updatePersonalSettings(params, callback, global) {
		if(typeof global !== 'boolean') global = true;

		if(!model.userProfile) return;

		var psettings = model.personalSettings;

		for(var key in params) psettings[key] = params[key];
		if(params.favoriteGames) params.favoriteGames = params.favoriteGames.join(',');

		this.sendRequest("UserDataManipulation", $.extend(true, {
			type: 'UpdatePersonalSettings',
			favoriteGames: Array.isArray(psettings.favoriteGames) ? psettings.favoriteGames.join(',') : '',
			lang: psettings.lang,
			soundEnabled: psettings.soundEnabled,
			paysafeToken: psettings.paysafeToken,
		}, params), callback, {global});
	},
	formatPersonalSettings(){
		clearTimeout(bcFavTimeout);

		if(!model.personalSettings) return;

		var psettings = model.personalSettings;
		if(psettings.root) psettings = psettings.root;

		psettings.favoriteGames = removeEmptyFromArr((psettings.favoriteGames + '').split(','));

		if(typeof psettings.soundEnabled === 'undefined') psettings.soundEnabled = true;
		model.personalSettings = psettings;
	}
}

function parseFavGames(gi, shouldDelete){	
	var gameIds = this.retrieveAllIDsFromGameID(gi);
	var favoriteGames = model.personalSettings.favoriteGames;
	var favoriteGames = Array.isArray(favoriteGames) ? favoriteGames.slice(0) : [];

	for(let i = gameIds.length - 1; i >= 0; i--){
		let gameId = gameIds[i] + '';
		if(shouldDelete){
			let giIndex = favoriteGames.indexOf(gameId);
			giIndex !== -1 && favoriteGames.splice(giIndex, 1);
		}
		else {
			let unwrappedID = this.unwrapGameID(gi);
			!favoriteGames.includes(unwrappedID) && favoriteGames.push(unwrappedID);
		}
	}

	return favoriteGames;
}