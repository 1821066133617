import Enum from "@shared/enum.js";
import controller from "./controller/controller.js";
import model from "./model.js";
import { toArray } from "@helpers/utils.js";

export default {
	getMobileApp(){
		let link = model.isIOS ? model.appAdvert?.appstoreLink : model.appAdvert?.playstoreLink;
		if(link) location.assign(link);
	},
	showCashierReceipt(){
		model.dispatchEvent('ClosePaymentWindow');
	},
	loginUser(username, pass){
		if(model.logged){
			model.dispatchEvent('GetStoredPass', {pass});
			return;
		}

		model.observables.storedUsername = username;
		controller.sendLoginRequest(username, pass, false);
	},
	onLogoClick(){
		location.assign(model.urls.homepage);
	},
	webRedir(redir){
		controller.openStandalonePage(redir);
	},
	hasPromoStarted(){
		return controller.hasPromoStarted.apply(this, arguments);
	},
	scrollToElem(elemSelector){
		$(document).scrollTop($(elemSelector).offset().top - model.vue.root.fixedTop.value);
	},
	openFAQ(){
		controller.goTo(Enum.UIContexts.FAQ);
	},
	openChat(){
		if(window.initZendesk) {
			model.on('ZendeskLoaded', e => {
				e.dispose();
				onShow();
			});
			window.initZendesk();
			return;
		}

		onShow();
		function onShow(){
			window?.$zopim?.livechat?.window?.show?.();
		}
	},
	getProviderNameByGGId(gameGroupID){
		if(!Array.isArray(model.providerConfig)) return null;

		var provider = model.providerConfig.find(item => item.gameGroups.includes(gameGroupID));
		if(provider) return provider.label || null;
		return null;
	},
	displayWOF(gameid, subType){
		model.dispatchEvent('DisplayWOF', {
			gameid: gameid,
			subType: subType,
		});
	},
	scrollToPromo(){
		model.dispatchEvent('ScrollToPromo');
	},
	scrollToTours(){
		model.dispatchEvent('ScrollToTours');
	},
	openTournamentTC(){
		controller.openTournamentTC.apply(controller, arguments);
	},
	focusCategoryTour(clickId){
		model.dispatchEvent('FocusCategoryTour', {
			clickId: clickId,
		});
	},
	dateToLocaleString(date, options){
		return (new Date(date)).toLocaleString(model.language, $.extend({
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			timeZoneName: 'short',
			hour: '2-digit', 
			minute: '2-digit',
			hour12: false,
		}, options));
	},
	closeNotification(){
		controller.closeNotification();
	},
	closeAllModals(){
		controller.closeAllModals();
	},
	getGameByID(){
		return controller.getGameByID.apply(controller, arguments);
	},
	startGame(){
		if(!model.logged){
			inner.apply(this, arguments);
			return;
		};

		controller.sendGetPlayerInfo(null).then(() => inner.apply(this, arguments));

		function inner(){
			let args = Array.prototype.slice.apply(arguments);
	
			let gameIDs = args[0];
			if(Array.isArray(gameIDs)){
				let legitGameId = gameIDs.find(x => controller.getGameByID(x));
				args[0] = legitGameId;
			}
	
			controller.startGame.apply(controller, args);
		}
	},
	autoActivateFilter(property, value){
		controller.whenAppReady(() => {
			var params = {};
			params[property] = value;
			model.dispatchEvent('AutoActivateFilter', params);
		});
	},
	goTo(uiContext){
		controller.goTo(uiContext);
	},
	goToRespGaming(limitPropName){
		let enumVal = Enum.Limits.Types[limitPropName];
		typeof enumVal === 'number' && sessionStorage.setItem('resp-gaming-open-limit', enumVal);
		controller.goTo(Enum.UIContexts.LIMITS);
	},
	loadPromoWin(id){
		controller.loadPromoWin(id);
	},
	gotoCategory(level1, level2, ifAuth, queryParams){
		var redirect = ifAuth ? model.logged : true;
		if(redirect){
			controller.gotoCategory({
				level1: level1,
				level2: level2,
				queryParams,
			});
		}
		else
			controller.goTo(Enum.UIContexts.LOGIN);
	},
	openTermsAndCond(){
		controller.sendWebResRequest(model.urls.webres.termsCond.url, null, model.isUI('FUND'));
		model.dispatchEvent('InfoOpened', {
			name: model.getStr('TermsCondLabel', 'en')
		});
	},
	openPrivacyPolicy(){
		controller.sendWebResRequest(model.urls.webres.privacy.url, null, model.isUI('FUND'));
		model.dispatchEvent('InfoOpened', {
			name: model.getStr('PrivacyLabel', 'en')
		});
	},
	openAltenarBR(){
		controller.webServiceSend({
			url: model.urls.webres.altenarBR,
			success: (xml) => {
				let $xml = $(xml);

				let title = $xml.find('title').html().replace("<![CDATA[", "").replace("]]>", "");
				let content = $xml.find('content').html().replace("<![CDATA[", "").replace("]]>", "");

				controller.displayHtmlContent(title, content);
			},
			type: 'GET',
			dataType: 'xml',
			contentType: false,
			processData: false,
		});
	},
	openRespGamingPage(){
		return openTermsDoc(model.urls.webres?.respGaming);
	},
	openCookiePolicy(){
		return openTermsDoc(model.urls.webres?.cookies);
	},
}

function openTermsDoc(propVal){
	if(!propVal) return;

	if(model.isUI('FUND')){
		if(propVal.path){
			var path = model.redeemLangObj(propVal.path);
			if(typeof path === 'string') controller.multipageRedir([path], true);
		}
		return;
	}

	if(propVal){
		controller.sendWebResRequest(propVal.url);
		model.dispatchEvent('InfoOpened', {
			name: model.getStr('CookiePolicyLabel', 'en')
		});
	}
}

function openWebResCallback(title, text){
	controller.showNotification({
		title: title,
		text: text,
	});
}