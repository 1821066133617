export const PlayForRealLabel = {
	"en": "Play For Real",
	"es": "Juega de verdad"
}

export const PlayDemoLabel = {
	"en": "Play Demo"
}

export const PlayFunLabel = { 
	"en": "Demo",
}

export const PlayMoneyLabel = { 
	"en": "Play for money",
	"el": "Παίξε για χρήματα",
	"de": "Um echtes Geld spielen",
	"es": "Juega Ahora"
}