export default {
	mpRoute_freeBet: {
		"en": "freebet-terms-and-conditions",
		"es": "terminos-de-freebet"
	},
	
	mpRoute_RiskQuestionnaire: {
		"en": "source-of-funds-declaration",
		"es": "declaracion-de-fuente-de-fondos"
	},
	
	mpRoute_Search: {
		"en": "search",
		"es": "buscar"
	},
	
	mpRoute_Promo_Previous: {
		"en": "previous"
	},
	
	mpRoute_Tour_Active: {
		"en": "active",
		"es": "activo"
	},
	
	mpRoute_Tour_Starting: {
		"en": "starting",
		"es": "proximamente"
	},
	
	mpRoute_Game: {
		"en": "game",
		"es": "juego"
	},
	
	mpRoute_Game_Play: {
		"en": "play",
		"es": "tocar"
	},
	
	mpRoute_Game_Demo: {
		"en": "demo",
		"es": "manifestacion"
	},
	
	mpRoute_LIMITS: {
		"en": "responsible-gaming",
		"es": "juego-responsable"
	},
	
	mpRoute_GAME_HISTORY: {
		"en": "game-history",
		"es": "historial-de-juego"
	},
	
	mpRoute_GAME_PREVIEW: {
		"en": "game-preview",
		"es": "revision-del-juego"
	},
	
	mpRoute_REGISTER: {
		"en": "register",
		"es": "registrarse"
	},
	
	mpRoute_CASHIER: {
		"en": "cashier",
		"es": "cajero"
	},
	
	mpRoute_ACCOUNT: {
		"en": "account",
		"es": "cuenta"
	},
	
	mpRoute_LOGIN: {
		"en": "login",
		"es": "accede"
	},
	
	mpRoute_SMS_ACTIVATION_FORM: {
		"en": "login-activation",
		"es": "acceso-activacion"
	},
	
	mpRoute_LOGIN_FORGOTTEN_PASSWORD: {
		"en": "forgot-password",
		"es": "olvidaste-la-contrasena"
	},
	
	mpRoute_LOGIN_RESET_PASSWORD: {
		"en": "password-reset",
		"es": "restabecer-contrasena"
	},
	
	mpRoute_USER_STATS: {
		"en": "balance"
	},
	
	mpRoute_DEPOSIT_PAYOUT: "deposit-payout",
	
	mpRoute_DEPOSIT: {
		"en": "deposit",
		"es": "depositar"
	},
	
	mpRoute_DEPOSIT_SINGLE_PAGE: {
		"en": "deposit-single-page",
		"es": "depositar-unica-pagina"
	},
	
	mpRoute_WITHDRAW: {
		"en": "withdrawal",
		"es": "retirar"
	},
	
	mpRoute_TRANS_HISTORY: {
		"en": "history",
		"es": "historial"
	},

	mpRoute_USER_NOTIF: {
		"en": "user-notifications",
		"es": "notificaciones-de-usuario"
	},
	
	mpRoute_USER_PROFILE: {
		"en": "profile",
		"es": "perfil"
	},
	
	mpRoute_EDIT_BANK_ACC: {
		"en": "bank-account",
		"es": "cuenta-bancaria"
	},
	
	mpRoute_UPLOAD_DOCS: {
		"en": "verification",
		"es": "verificacion"
	},
	
	mpRoute_CHANGE_PASSWORD: {
		"en": "security",
		"es": "seguridad"
	},

	mpRoute_LOTTERY_TICKETS: {
		"es": "fundalotto"
	},

	mpRoute_REFERRAL_PAGE: {
		"es": "funfriends"
	},
}