import { isObject } from "@helpers/utils.js"
import { nextTick } from "vue"

import model from "../model.js";
import controller from "../controller/controller.js";

export default function(){
	var fitTexts = [];
	
	model.on('WindowResize', 'ForceResize', () => {
		fitTexts.forEach(props => {
			// if the font-size value is zero, then the 
			// element is not visible to begin with
			if(Number(props.$elem.css('font-size').replace(/[^0-9.]/g, '')) > 0) fitText(props.$elem, props.fitText);
		});
	});
	
	function beforeMount(element, binding){
		var props = binding.value;
		var $element = $(element);
	
		if(!Boolean(props)) return;
	
		if(isObject(props.onClick) && props.onClick.selector && typeof props.onClick.callback === 'function'){
			nextTick(() => $element.find(props.onClick.selector).on('click', () => props.onClick.callback()));
		}
	
		if(props.fitText){
			props.fitText = typeof props.fitText === 'boolean' ? {} : props.fitText;
	
			if(typeof props.fitText.parentSelector === 'string'){
				var $p = $element.closest(props.fitText.parentSelector);
				if($p && $p.length > 0)
					props.fitText.$parent = $p;
			}
	
			props.fitText = $.extend({
				maxSize: 14,
			}, props.fitText);
	
			fitTexts.push({
				$elem: $element,
				fitText: props.fitText,
			});
	
			if(props.fitText.parent){
				props.fitText.parent.updateFitText = function(){
					fitText($element, props.fitText);
				};
			}
	
			$element.setData('lang-fit-text', true);
		}
	
		updated(element, binding);
	}
	
	function areValuesTheSame(value, oldValue){
		let fitTextParent;
		let oldFitTextParent;
	
		if(value?.fitText?.parent) {
			fitTextParent = value.fitText.parent;
			value.fitText.parent = null;
		}
	
		if(oldValue?.fitText?.parent) {
			oldFitTextParent = oldValue.fitText.parent;
			oldValue.fitText.parent = null;
		}
	
		let bool = fitTextParent === oldFitTextParent && JSON.stringify(value) === JSON.stringify(oldValue);
	
		isObject(value?.fitText) && (value.fitText.parent = fitTextParent);
		isObject(oldValue?.fitText) && (oldValue.fitText.parent = oldFitTextParent);
		
		return bool;
	}
	
	function updated(element, binding){
		var props = binding.value;
		var $element = $(element);
	
		if(areValuesTheSame(binding.value, binding.oldValue)) return;
		
		if(!Boolean(props)) {
			$element.attr('placeholder', '');
			return;
		}
	
		var fitTextProps = fitTexts.find(ft => ft.$elem.get(0) === element);
		if(isObject(fitTextProps)) fitTextProps = fitTextProps.fitText;
	
		element.langDisposeCallback?.();
		element.langDirectiveCallback = model.on('SetLang', () => {
			if(typeof props === 'string')
				insertText($element, model.getStr(props, null, true));
			else if(typeof props === 'object' && props !== null){
				var text = props.text || '';
				if(typeof props.label === 'string') text = model.getStr(props.label, null, true);
				else if(isObject(props.label)) text = model.redeemLangObj(props.label);
	
				var placeholders = props.placeholders;
				if(typeof placeholders === 'object' && placeholders !== null){
					for(var key in placeholders){
						var val = placeholders[key];
	
						var indOpen = 0;
						var indClose = 0;
						var langKey = null;
	
						if(typeof val === 'string' && val){
							while(true){
								indOpen = val.indexOf('{');
								indClose = val.indexOf('}');
								if(indOpen === -1 || indClose === -1)
									break;
	
								langKey = val.substring(indOpen + 1, indClose);
								val = val.replace(new RegExp('\\{' + langKey + '\\}', 'g'), model.getStr(langKey));
							}
						}
	
						text = (text+'').replace(new RegExp('\\{' + key + '\\}', 'g'), val);
					}
				}
				
				insertText($element, text);
			}
	
			fitText($element, fitTextProps);
		});
	
		element.langDirectiveCallback();
	
		element.langDisposeCallback = () => {
			model.off('SetLang', element.langDirectiveCallback);
			fitTexts.includes(fitTextProps) && fitTexts.splice(fitTexts.indexOf(fitTextProps), 1);
		};
	}
	
	function insertText($element, text){
		if($element.data('lang-fit-text')) $element.html(`<div><span>${text}</span><span class="shown">${text}</span></div>`);
		else controller.langHandler.setLangToNodes($element, text);
	}
	
	function fitText($element, fitTextProps){
		if(!$element.data('lang-fit-text'))
			return;
	
		onFitText($element, fitTextProps);
		setTimeout(() => onFitText($element, fitTextProps), 0);
	}
	
	function onFitText($element, fitTextProps){
		var $span = $element.find('.shown');
		var dim = checkIfTextOutOfBounce(fitTextProps.$parent || $element);
		var percDiff = dim.parentWidth / dim.spanWidth;
		var currFontSize = Number($element.css('font-size').replace(/[^0-9.]/g, ''));
		var newFontSize = Math.floor(currFontSize * percDiff); //floor fixes a resizing issue when scrolling up and down on iOS
		if(newFontSize > fitTextProps.maxSize)
			newFontSize = fitTextProps.maxSize;
		$span.css('font-size', newFontSize);
	}
	
	function checkIfTextOutOfBounce($parent){
		var spanWidth = $parent.find('span:not(.shown)').outerWidth();
		var paddingLeft = $parent.css('paddingLeft').replace(/[^0-9.]/g, '');
		var paddingRight = $parent.css('paddingRight').replace(/[^0-9.]/g, '');
		var borderLeft = $parent.css('border-left-width').replace(/[^0-9.]/g, '');
		var borderRight = $parent.css('border-right-width').replace(/[^0-9.]/g, '');
		return {
			spanWidth: spanWidth,
			parentWidth: $parent.outerWidth() - Number(paddingLeft) - Number(paddingRight) - Number(borderLeft) - Number(borderRight),
		};
	}
	
	model.vue.app.directive('lang', {
		beforeMount,
		updated,
		unmounted(element){
			element.langDisposeCallback?.();
		}
	});
}