import model from "@js/model.js";
import { toArray, isObject, firstIfUndefined, checkTimstpBetweenCurrTimstp, appendParamsToJson } from "@helpers/utils.js"
import { reactive, watch } from "vue"
import Enum from "@shared/enum.js";
import { addZero } from "@helpers/calendar-contents.js";

let deferredSubscrs = [];
let uniqueCounter = 0;

export default  {
    initPromotions(){
        model.promos = reactive({
            all: [],
            previous: [],
            future: [],
        });
        model.tournaments = reactive({
            all: [],
            active: [],
            starting: [],
            ended: [],
            future: [],
            activeAndStarting: [],
        });

        model.arrPromoCarousel = reactive({value: []});

        watch(() => model.promos.all, () => {
            var arr = model.promos.all.slice(0);
            for (var i = 0, len = arr.length; i < len; i++) {
                var promo = arr[i];
                if (typeof promo.promoCarouselPosition !== 'number' || isNaN(promo.promoCarouselPosition)) {
                    arr.splice(i, 1);
                    i--;
                    len--;
                }
            }
            arr.sort((p1, p2) => p1.promoCarouselPosition - p2.promoCarouselPosition);
            model.arrPromoCarousel.value = arr;
        });

        watch(() => model.tournaments.all, () => updateAllTours.call(this));

        (() => {
            updateToursHiddenState.call(this);
            model.on('ListUpdated_tournaments', () => updateToursHiddenState.call(this));
            
            function updateToursHiddenState(){
                var tours = model.tournaments.all.filter((promo) => promo.tournamentId); 
                tours.forEach(tour => {
                    tour.visible = this.tournamentVisible(tour.tournamentId);
                    tour.playNowVisible = this.tournamentStarted(tour.tournamentId);
                });
                updateAllTours.call(this);
            }
        })();
    },
    setUrlPathnameForPromo(promoId){
        let redirPage = (model.tournaments.all.find(item => item.id === promoId) || model.currCatIds.level1 === Enum.CATEGORY_IDS.TOURS) ? Enum.MultiPage.Tournaments : Enum.MultiPage.Promotions;
        redirPage = model.redeemLangObj(redirPage);
        
        let promo = this.getPromoById(promoId);
        if(!promo) return;

        if(model.tournaments.all.find(item => item.id === promo.id)){
            let psubpage = model.getStr(model.tournaments.active.find(item => item.id === promo.id) ? 'mpRoute_Tour_Active' : 'mpRoute_Tour_Starting');
            this.multipageRedir([redirPage, psubpage, promo.route || promo.id], false, null/*, {avoidLoginWheel: params.avoidLoginWheel}*/);
        }
        else {
            let multipage = [redirPage];
            if(model.promos.previous.find(item => item.id === promo.id)) multipage.push(model.getStr('mpRoute_Promo_Previous'));
            multipage.push(promo.route || promo.id);
            this.multipageRedir(multipage, false, null, /*{avoidLoginWheel: params.avoidLoginWheel}*/);
        }
    },
    hasPromoStarted(promo){
        return (!promo.dateStartsIn && !promo.dateEndsIn) || checkTimstpBetweenCurrTimstp(promo.dateStartsIn, promo.dateEndsIn);
    },
    setupPromoModels(){
        if(uniqueCounter > 10000) uniqueCounter = 0;

        if(!Array.isArray(model.promoList)) return;
        
        while(deferredSubscrs.length > 0){
            deferredSubscrs[0]();
            deferredSubscrs.shift();
        }

        var promos = [];
        var tournaments = [];

        for(var i = 0, len = model.promoList.length; i < len; i++){
            var promo = model.promoList[i];

            let tournamentData = this.getExtAPI('tournaments')?.data?.find(x => (promo.id + '').includes(x.TournamentId));
            if(tournamentData){
                if(!promo.params) promo.params = {};

                [
                    {
                        timezone: '-utc',
                        offset: 0,
                    },
                    {
                        timezone: '-mx',
                        offset: -6,
                    },
                ].forEach(item => {
                    promo.params[`server-start-date${item.timezone}`] = formatDate(tournamentData.StartDate, item.offset, false);
                    promo.params[`server-end-date${item.timezone}`] = formatDate(tournamentData.EndDate, item.offset, false);
                    promo.params[`server-start-date${item.timezone}-full`] = formatDate(tournamentData.StartDate, item.offset, true);
                    promo.params[`server-end-date${item.timezone}-full`] = formatDate(tournamentData.EndDate, item.offset, true);
                });

                promo.params = appendParamsToJson(promo.params, promo.params);
            }

            if(typeof promo?.frontInfo?.date === 'string'){
                Object.keys(promo.params).forEach(label => {
                    promo.frontInfo.date = promo.frontInfo.date.replace(`{${label}}`, promo.params[label]);
                });
            }

            if(typeof promo.hidden !== 'boolean') promo.hidden = false;
            
            if(promo.hidden) continue;

            if(typeof promo.prod !== 'boolean') promo.prod = true;

            if(model.isProd && !promo.prod) continue;
            
            var langs = toArray(promo.lang);
            if(langs.length > 0){
                var validLang = toArray(promo.lang).includes(model.langKey);
                if(!validLang) continue;
            }

            ['innerText', 'innerTextCarousel'].forEach(label => {
                if(isObject(promo[label])){
                    promo[label].text = model.redeemLangObj(promo[label].text);
                    promo[label].subText = model.redeemLangObj(promo[label].subText);
                }
            });

            if(isObject(promo.frontInfo)){
                for(var fInfoKey in promo.frontInfo){
                    promo.frontInfo[fInfoKey] = model.redeemLangObj(promo.frontInfo[fInfoKey]);
                }
            }

            promo.visible = promo.tournamentId != null ? this.tournamentVisible(promo.tournamentId) : promo.visible;
            if(typeof promo.visible !== 'boolean') promo.visible = true;

            var playNowVisible = true;
            if(promo.tournamentId) playNowVisible = this.tournamentStarted(promo.tournamentId);

            var newPromo = createPromoModel.call(this, $.extend({}, promo, {
                uniqueID: uniqueCounter++,
                srcImg: getSrcImage.call(this, promo, false),
                srcChildImg: getSrcImage.call(this, promo, true),
                title: firstIfUndefined(promo.title, model.lang),
                text: firstIfUndefined(promo.text, model.lang),
                playNowVisible: playNowVisible,
            }));

            if(!promo.dateFrom || !promo.dateTo || checkTimstpBetweenCurrTimstp(promo.dateFrom, promo.dateTo)){
                (p => {
                    let unwatch = watch(() => p.inViewport, bool => {
                        if(!bool) return;
                        loadPromoImg.call(this, p);
                        unwatch();
                        if(deferredSubscrs.includes(unwatch)) deferredSubscrs.splice(deferredSubscrs.indexOf(unwatch), 1);
                    });
                    deferredSubscrs.push(unwatch);
                })(newPromo);
                if(model.isTournament(promo)) tournaments.push(newPromo);
                else promos.push(newPromo);
            }
            else if (model.prevPromosActive && promo.dateTo && promo.dateTo < Date.now()){
                newPromo.ctaBtns = newPromo.ctaBtns.filter(btn => btn.isLearnMore);
                if(model.isTournament(promo)) tournaments.push(newPromo);
                else model.promos.previous.push(newPromo);
            }
            else if(promo.dateFrom > Date.now()){
                if(model.isTournament(promo)) tournaments.push(newPromo);
                else model.promos.future.push(newPromo);
            }
        }

        model.promos.previous.sort((p1, p2) => {
            var date1 = p1.dateStartsIn || p1.dateTo;
            var date2 = p2.dateStartsIn || p2.dateTo;
            return date2 - date1;
        });

        model.promos.previous = model.promos.previous.slice(0, 5);

        model.promos.all = promos;
        model.tournaments.all = tournaments;

        function formatDate(dateInMillis, timezoneOffsetInHours, includeHoursMins){
            if(dateInMillis instanceof Date) dateInMillis = dateInMillis.getTime();

            let dateObj = new Date(dateInMillis + timezoneOffsetInHours * (60 * 60 * 1000));

            let year = dateObj.getUTCFullYear();
            let month = dateObj.getUTCMonth() + 1;
            let date = dateObj.getUTCDate();
            let hours = dateObj.getUTCHours();
            let minutes = dateObj.getUTCMinutes();
            
            return `${addZero(date)}.${addZero(month)}.${year}${includeHoursMins ? ` ${addZero(hours)}:${addZero(minutes)}` : ''}`;
        }
    },
    comparePromoDateFromTo(promo){
        return promo.dateFrom && promo.dateTo && checkTimstpBetweenCurrTimstp(promo.dateFrom, promo.dateTo);
    }
}

function updateAllTours(){
    let active = [];
    let starting = []; 
    let ended = []; 
    let future = [];

    model.tournaments.all.forEach((tour) => {
        if(tour.dateFrom > Date.now()) future.push(tour);
        else if(tour.tournamentId){
            if(this.tournamentStarting(tour.tournamentId)) starting.push(tour);
            if(this.tournamentStarted(tour.tournamentId)) active.push(tour);
            if(this.tournamentEnded(tour.tournamentId)) ended.push(tour);
        }
        else if (model.prevPromosActive && tour.dateTo && tour.dateTo < Date.now()) ended.push(tour);
        else if(tour.dateStartsIn && tour.dateEndsIn){
            if(checkTimstpBetweenCurrTimstp(tour.dateFrom, tour.dateStartsIn)) starting.push(tour);
            else if(checkTimstpBetweenCurrTimstp(tour.dateStartsIn, tour.dateEndsIn)) active.push(tour);
            else if(checkTimstpBetweenCurrTimstp(tour.dateEndsIn, tour.dateTo)) ended.push(tour);
        }
        else active.push(tour);
    });

    model.tournaments.active = this.sortPromosAndBanners(active);
    model.tournaments.starting = this.sortPromosAndBanners(starting);
    model.tournaments.ended = this.sortPromosAndBanners(ended).splice(0, 5);
    model.tournaments.future = this.sortPromosAndBanners(future);
    model.tournaments.activeAndStarting = this.sortPromosAndBanners(active.concat(starting));
}

function getSrcImage(promo, isChild){
    var srcImage = promo[isChild ? 'srcChildImage' : 'srcImage'];
    if(!srcImage)
        return null;

    var keySiteID = isChild ? 'srcChildImageSiteID' : 'srcImageSiteID';
    if(promo[keySiteID])
        srcImage = promo[keySiteID][model.getKey(model.siteId, Enum.SiteIDs)];

    if(isObject(srcImage) && srcImage.prod && srcImage.test)
        srcImage = srcImage[model.isProd ? 'prod' : 'test'];

    srcImage = model.redeemLangObj(srcImage);

    return srcImage;
}

function loadPromoImg(promo){
    if(!promo.visible){
        let unwatch = watch(() => promo.visible, bool => {
            if(!bool) return;
            
            onPreloadImg.call(this);

            unwatch();
            var subscrInd = deferredSubscrs.indexOf(subscr);
            subscrInd !== -1 && deferredSubscrs.splice(subscrInd, 1);
        });
        deferredSubscrs.push(unwatch);
        return;
    }

    onPreloadImg.call(this);
    model.dispatchEvent('ForceResize');

    function onPreloadImg(){
        this.preloadImg({
            imgUrl: promo.srcImg,
            success: (img, elem) => {
                if(typeof promo.innerTextCanvas === 'string'){
                    let canvas = document.createElement('canvas');

                    canvas.setAttribute('width', elem.width);
	                canvas.setAttribute('height', elem.height);

                    let ctx = canvas.getContext('2d');

                    ctx.drawImage(elem, 0, 0);

                    (new Function('ctx', 'promo', 'model', promo.innerTextCanvas))(ctx, promo, this.model);

                    let newImg = canvas.toDataURL('image/png');
                    promo.srcImg = newImg;
                    promo.srcImgDispl = newImg;
                }
                else if(!promo.srcImgDispl){
                    promo.srcImg = img;
                    promo.srcImgDispl = promo.srcImg;
                }
            },
        });

        preloadChildImgIfExists.call(this, promo);
    }
}

let canvasHelperModule = null;

function preloadChildImgIfExists(promo){
    let cChild = promo.canvasChildImg;
    if(model.promoCanvas && isObject(cChild)){
        for(let key in cChild) cChild[key] = model.redeemLangObj(cChild[key]);

        (() => {
            if(canvasHelperModule) inner.call(this);
            else{
                import("@dom/canvas-helper.js").then(module => {
                    canvasHelperModule = module;
                    inner.call(this);
                });
            }

            function inner(){
                canvasHelperModule.createPromoGraphics({
                    title: cChild.title,
                    titleLabel: cChild.titleLabel,
                    text: cChild.text,
                    subText: cChild.subText,
                    width: cChild.width,
                    titleOffsetX: cChild.titleOffsetX,
                }, img => {
                    if(!promo.srcChildImgDispl){
                        promo.srcChildImg = img;
                        promo.srcChildImgDispl = promo.srcChildImg;
                    }
                });
            }
        })();
    }
    else{
        typeof promo.srcChildImg === 'string' && this.preloadImg({
            imgUrl: promo.srcChildImg,
            success: img => {
                if(!promo.srcChildImgDispl){
                    promo.srcChildImg = img;
                    promo.srcChildImgDispl = promo.srcChildImg;
                }
            },
        });
    }
}

function createPromoModel(params){
    let obs = {};
    $.extend(obs, params);

    obs.promoCarouselObs = null;

    if (typeof obs.loadPromoTCPage !== 'boolean') obs.loadPromoTCPage = true;
    if (typeof obs.visible !== 'boolean') obs.visible = true;

    obs.srcImgDispl = '';
    obs.srcChildImgDispl = '';
    obs.inViewport = false;

    obs.displayDelay = 0;

    var depNowVisible = true;
    if (typeof obs.depositNow === 'boolean' && obs.depositNow && typeof obs.dateStartsIn === 'number') {
        depNowVisible = obs.dateStartsIn < Date.now();
    }

    if (obs.dateTo && obs.dateTo < Date.now()) depNowVisible = false;

    obs.depositNowVisible = depNowVisible;
    obs.playNowVisible = params.playNowVisible;

    obs.isTourn = obs.type == 'local-tournament' || obs.type == 'network-tournament';

    return reactive(obs);
}