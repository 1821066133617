import { addQueryStringValues, getKeyByValue, appendParamsToJson, getPaths, setLocationSearch, loadScript, propNameLowerCase, removeEmptyFromArr, isObject, getParameterByName, removeItemFromArray, createCORSSafeWorker } from "@helpers/utils.js"
import { watch } from "vue"
import Enum from "@shared/enum.js";

import popState from "./partial-controllers/pop-state.js";
import notification from "./partial-controllers/notification.js";
import user from "./partial-controllers/user.js";
import account from "./partial-controllers/account.js";
import cashier from "./partial-controllers/cashier.js";
import filters from "./partial-controllers/filters.js";
import multiPage from "./partial-controllers/multi-page.js";
import categories from "./partial-controllers/categories.js";
import sportBetting from "./partial-controllers/sport-betting.js";
import externalApis from "./partial-controllers/external-apis.js";
import freeGames from "./partial-controllers/free-games.js";
import thirdpartyJackpots from "./partial-controllers/thirdparty-jackpots.js";
import gameHandling from "./partial-controllers/game-handling.js";
import tournaments from "./partial-controllers/tournaments.js";
import images from "./partial-controllers/images.js";
import jackpot from "./partial-controllers/jackpot.js";
import keepAlive from "./partial-controllers/keep-alive.js";
import login from "./partial-controllers/login.js";
import modal from "./partial-controllers/modal.js";
import personalSettings from "./partial-controllers/personal-settings.js";
import webRes from "./partial-controllers/web-res.js";
import promos from "./partial-controllers/promos.js";
import currencyConversion from "./partial-controllers/currency-conversion.js";
import maintenanceSetup from "./partial-controllers/maintenance-setup.js";
import x1 from "./partial-controllers/x1.js";
import blog from "./partial-controllers/blog.js";

import model from "@js/model.js";

import SiteWorkerUrl from './web-workers/main-worker.js?worker&url';
import { script_load_error } from "@lang/cashier.js";
import { ClientMessageTitleFailure, ClientMessageTitleSuccess, ReloadPageLabel, Retry } from "@lang/user.js";
import dynamicImportLoad from "@helpers/dynamic-import-load.js";

let partialControllers = [
	popState,
	notification,
	user,
	account,
	cashier,
	filters,
	multiPage,
	categories,
	sportBetting,
	externalApis,
	freeGames,
	thirdpartyJackpots,
	gameHandling,
	tournaments,
	images,
	jackpot,
	keepAlive,
	login,
	modal,
	personalSettings,
	webRes,
	promos,
	currencyConversion,
	maintenanceSetup,
	x1,
	blog,
];

const loadedScripts = [];
const pendingScripts = [];

let classController = class Controller {
	constructor(model) {
		this.model = model;
	}
	init(){
		model.pushLang({
			script_load_error, 
			Retry, 
			ReloadPageLabel, 
			ClientMessageTitleSuccess, 
			ClientMessageTitleFailure, 
		});

		model.pendingScripts = 0;

		model.loadedComponents = [];

		if(typeof model.newReqStruct !== 'boolean') model.newReqStruct = false;
		if(typeof model.isProd !== 'boolean') model.isProd = false;

		model.observables.gamesReady = false;
		model.observables.workerScriptsLoaded = false;

		model.gamesFetched = false;
		model.gamesFetching = false;

		this._setupUserFlagsDefault();
		model.on('LogoutComplete', () => this._setupUserFlagsDefault());

		model.on('HandleServerError', () => {
			dynamicImportLoad(async () => {
				const { ServerConnError } = await import("@lang/misc.js");
				model.pushLang({ServerConnError});
			}).then(() => {
				this.showNotification({
					title: model.getStr('warning'),
					text: model.getStr('ServerConnError'),
					buttons: [
						{
							type: Enum.ButtonTypes.SUCCESS,
							label: model.getStr('ReloadPageLabel'),
							onClick: () => window.location.reload(),
						}
					]
				});
			});
		});

		window.addEventListener("message", event => {
			model.dispatchEvent('PostMessageReceived', {event: event});
		}, false);

		model.ajaxPreloading = false;
		this._setAjaxStartStopCallbacks();

		var partialControllersList = [
			'initPopState',
			'initNotification',
			'initUser',
			'initAccount',
			'initCashier',
			'initFilters',
			'initMultiPage',
			'initCategories',
			'initSportBetting',
			'initExternalApis',
			'initThirdPartyJackpots',	
			'initGameHandling',
			'initTournaments',
			'initImages',
			'initJackpot',
			'initKeepAlive',
			'initLogin',
			'initModal',
			'initPersonalSettings',
			'initWebRes',
			'initPromotions',
			'initCurrencyConversion',
			'initMaintenanceSetup',
			'initX1',
			'initBlog',
		];

		for(var i = 0, len = partialControllersList.length; i < len; i++) this[partialControllersList[i]].call(this);

		(() => {
			model.games = [];

			this.webWorker = createCORSSafeWorker(SiteWorkerUrl);

			if(model.viewExpectingGames){
				let now = Date.now();
				for(var i = 0, j = model.urls.gameConfig.length; i < j; i++){
					model.urls.gameConfig[i] = addQueryStringValues(model.urls.gameConfig[i], {
						'_': now,
					});
				}

				model.urls.gameConfig.forEach((__, index) => {
					(model.excludedIndexFileNames || []).forEach(fileName => {
						model.urls.gameConfig[index] = model.urls.gameConfig[index].replace(fileName, '');
					});
				});
			}

			this.webWorker.onmessage = e => {
				let state = e.data.state;
				if(state.includes('games') && !model.viewExpectingGames) return;

				switch(state){
					case 'load-scripts' : 
						model.observables.workerScriptsLoaded = true;
						if(model.viewExpectingGames) this.sendToWorker('setup-all-games');
						break;
					case 'setup-all-games': 
						for(let i = model.providerConfig.length - 1; i >= 0; i--){
							let config = model.providerConfig[i];
							if(config && typeof config.disabledFromHostname === 'string'){
								if(location.origin.toLowerCase().includes(config.disabledFromHostname.toLowerCase())){
									model.providerConfig.splice(i, 1);
									continue;
								}
							}
						}	

						model.latestGames = e.data.latestGames;
						setTimeout(() => model.observables.gamesReady = true, 0);
						break;
					case 'get-all-games': 
						model.games = e.data.games;
						model.gameMap = e.data.gameMap;
						model.allLatestGamesByCatID = e.data.allLatestGamesByCatID;
						model.gamesFetched = true;
						model.gamesFetching = false;
						model.dispatchEvent('GamesFetched');
						break;
					case 'get-promo-list' : 
						model.promoNotifList = e.data.promoNotifList,
						model.promoList = e.data.promoList,
						model.bannerData = e.data.bannerData,
						model.categoryTours = e.data.categoryTours,
						model.dispatchEvent('WorkerPromoList');
						break;
				}
			};

			this.sendToWorker('load-scripts');
		})();

		model.dispatchEvent('ControllerInitialized');
	}
	onInitialized(callback){
		model.on('ControllerInitialized', e => {
			e.dispose();
			callback();
		});
	}
	sendToWorker(state, extraProps){
		let props = {
			scripts: model.urls.gameConfig,
			isMobile: model.isMobile,
			isAndroid: model.isAndroid,
			isIOS: model.isIOS,
			isWinPhone: model.isWinPhone,
			assets: model.urls.assets,
			categories: model.categories,
			newGamesAmount: model.newGamesAmount,
			activeSvgImgs: model.activeSvgImgs,
			deactivatedGames: model.deactivatedGames,
			webpSupported: model.webpSupported,
			isProd: model.isProd,
			providerConfig: model.providerConfig,
			lobbyHelper: model.lobbyHelper,
			siteId: model.siteId,
			UIID: model.UIID,
			langKey: model.langKey,
			lang: model.lang,
			currency: model.getCurrency(),
			currencies: model.currencies,
			allupcomingpromos: getParameterByName('allupcomingpromos') == 'true',
			logged: model.logged,
			firstDepCompleted: model.logged && model.deposits() > 1,
			queryString: location.search,
			country: model.userProfile?.country || model.guestCountry,
			isIOS: model.isIOS,
			isApp: model.isApp,
		};

		$.extend(props, extraProps || {});

		props.model = $.extend({}, props);
		props.state = state;

		this.webWorker.postMessage(props);
	}
	async getBusiness(label, importFunct, global){
		if(!this[label]) this[label] = await dynamicImportLoad(importFunct, global);
		return this[label];
	}
	controllerBusiness(){
		return this.getBusiness('__controllerBusiness', () => import("./business/controller-business.js"));
	}
	setupXmlToJson(){
		let global = arguments[1];
		return new Promise(resolve => {
			if(typeof global !== 'boolean') global = true;
			dynamicImportLoad(() => import("@helpers/xml-to-json.js"), global).then(module => {
				if(!this.__xmlToJson) this.__xmlToJson = module.default;
				resolve(this.__xmlToJson.apply(null, arguments));
			});
		});
	}
	setupXmlParamsConds(){
		let global = arguments[2];
		return new Promise(resolve => {
			if(typeof global !== 'boolean') global = true;
			dynamicImportLoad(() => import("@helpers/xml-params-conds.js"), global).then(module => {
				if(!this.__xmlParamsConds){
					this.__xmlParamsConds = module.default;
					this.__xmlParamsConds.addGlobalVar('model', this.model);
					this.__xmlParamsConds.addGlobalVar('Enum', Enum);
				}
	
				resolve(this.__xmlParamsConds.apply(null, arguments));
			});
		});
	}
	sendJSONRequest({url, type, data, noDataType, global}){
		if(typeof noDataType !== 'boolean') noDataType = false;
		if(typeof global !== 'boolean') global = true;
		
		return new Promise(resolve => {
			this.webServiceSend({
				url,
				data,
				type: type || 'GET',
				global,
				dataType: noDataType ? undefined : 'json',
				contentType: "application/json; charset=utf-8",
				processData: false,
				success: data => resolve(data),
			});
		});
	}
	loadClientConfig(label, global){
		return new Promise(resolve => {
			if(typeof global !== 'boolean') global = false;

			if(!isObject(model.cconfPaths) || typeof model.cconfPaths?.[label] !== 'string') {
				resolve(null);
				return;
			}

			this.webServiceSend({
				url: model.cconfPaths[label],
				success: data => {
					data = appendParamsToJson(data, {
						'cdn-path': model.cdnPath,
						'web-res-path': model.webResPath,
					});
					
					resolve(data);
				},
				type: 'GET',
				global,
				dataType: 'json',
				contentType: false,
				processData: false,
			});
		});
	}
	fetchPreparedGames(callback, showPreloader){
		if(!model.viewExpectingGames){
			callback?.();
			return;
		}

		this.whenAppReady(() => {
			if(model.gamesFetched || !this.webWorker) {
				callback?.();
				return;
			}
	
			model.on('GamesFetched', (e) => {
				e.dispose();
				callback?.();
			});

			if(showPreloader) model.dispatchEvent('ShowPreloader');

			if(model.gamesFetching) return;
			model.gamesFetching = true;

			this.sendToWorker('get-all-games');
		});
	}
	getPaths(){
		return getPaths();
	}
	updatePathname(arrPaths){
		var path = arrPaths.length > 0 ? '/' + arrPaths.join('/') : '';
		setLocationSearch(null, path + window.location.search, false);
	}
	sendRequest(actionType, params, callback, extraArgs) {
		let {error, overriddenActionProps, showClientFailure, global} = extraArgs || {};

		global = typeof global === 'boolean' ? global : !['KeepAlive', 'JackpotInfo', 'BonusMultiplierWheel', 'GetNotification', 'CheckUsername'].includes(actionType);
		if(actionType === 'BonusMultiplierWheel' && params.deactivate) global = true;

		if(this.message){
			inner.call(this);
			return;
		}

		(async () => {
			const MessageLobby = await dynamicImportLoad(() => import("./message-lobby.js"), global);
			this.message = new MessageLobby.default(this.model);
			inner.call(this);
		})();

		function inner(){
			let xml = this.message.generateMessage(actionType, params, overriddenActionProps);
			this.webServiceSend({
				xml: xml,
				global: global,
				success: callback,
				error: error,
				showClientFailure,
			});
		}
	}
	webServiceSend(params){
		if(typeof params.global !== 'boolean') params.global = true;

		if(this.webservice){
			this.webservice.send(params);
			return;
		}

		(async () => {
			const WebService = await dynamicImportLoad(() => import("./web-service.js"), params.global);
			this.webservice = new WebService.default(this.model, model.urls.service);
			this.webservice.on("ResponseReceived", e => {
				this.controllerBusiness().then(module => module.handleServerResponse.call(this, e));
			});
			this.webservice.send(params);
		})();
	}
	unwrapAction(json){
		if(json?.error){
			this.showNotification({
				title: 'Error',
				text: json?.error.message,
			});
			return;
		}

		var action = null;
		if(json.preExecuteXml) action = json.preExecuteXml.xmlElement;
		else if(json.preExecuteXmlResponse) action = json.preExecuteXmlResponse.preExecuteXmlResult;
		else if(json.executeXmlResponse) action = json.executeXmlResponse.executeXmlResult;
		else if(json.executeXml)action = json.executeXml.xmlElement;
		else if(json.action) action = json;
		return action.action;
	}
	scriptError(onRetry){
		this.showNotification({
			title: model.getStr('ClientMessageTitleFailure'),
			text: model.getStr('script_load_error'),
			buttons: [
				{
					label: model.getStr('Retry'),
					onClick: () => typeof onRetry === 'function' && onRetry(),
				}
			],
		});
	}

	getScript(url, global){
		if(loadedScripts.includes(url)) return Promise.resolve();

		if(pendingScripts.includes(url)){
			return new Promise(resolve => {
				let int = setInterval(() => {
					if(loadedScripts.includes(url)){
						clearInterval(int);
						resolve();
					}
				}, 500);
			});
		}

		function _getScript(url, global, resolve){
			this._showPL(global);
			pendingScripts.push(url);
			loadScript(url, () => {
				this._hidePL(global);
				loadedScripts.push(url);
				removeItemFromArray(pendingScripts, url);
				resolve();
			}, () => setTimeout(() => _getScript.apply(this, arguments), 2000));
		}

		return new Promise(resolve => _getScript.call(this, url, global, resolve));
	}

	_showPL(global){
		if(!global) return;

		model.pendingScripts++;
		model.dispatchEvent('ShowPreloader');
	}
	_hidePL(global){
		if(!global) return;

		model.pendingScripts--;
		model.dispatchEvent('HidePreloader');
	}

	whenComponentReady(componentName){
		return new Promise(resolve => {
			if(model.loadedComponents.includes(componentName)) resolve();
			model.on(`${componentName}ComponentLoaded`, e => {
				e.dispose();
				resolve();
			});
		});
	}

	whenAppReady(callback){
		if(typeof callback !== 'function') return;

		if(!model.viewExpectingGames){
			callback();
			return;
		}

		if(!model.observables.gamesReady){
			let unwatch = watch(() => model.observables.gamesReady, () => {
				callback();
				unwatch();
			});
			return;
		}

		callback();
	}

	_setAjaxStartStopCallbacks(){
		$(document).ajaxStop(() => {
			model.ajaxPreloading = false;
			model.dispatchEvent('HidePreloader');
		}).ajaxStart(() => {
			model.ajaxPreloading = true;
			model.dispatchEvent('ShowPreloader');
		});
	}

	_setupUserFlagsDefault(){
		model.userFlags = {};
		for(var key in Enum.UserFlags) model.userFlags[propNameLowerCase(key)] = false;
	}

	_setupUserFlags(action){
		let userFlagStr = action.userFlagStr;
		if(!userFlagStr) {
			flagsTest.call(this);
			return;
		}

		var flags = removeEmptyFromArr((userFlagStr + '').split(',').map(Number));
		
		flags.forEach(id => {
			if(isNaN(id)) return;
			var key = propNameLowerCase(getKeyByValue(Enum.UserFlags, id));
			model.userFlags[key] = flags.includes(id);
		});

		flagsTest.call(this);

		function flagsTest(){
			if(import.meta.env.DEV){
				if(getParameterByName('testwithdrawdisabled') == 'true'){
					model.userFlags.withdrawDisabled = true;
				}
				if(getParameterByName('testwithdrawEnabled') == 'true'){
					model.userFlags.withdrawDisabled = false;
				}
			}
		}
	}
}

partialControllers.forEach(partialController => {
	for(let propName in partialController){
		classController.prototype[propName] = partialController[propName];
	}
});

export default new classController(model);